














import Component from 'vue-class-component';

import UiPageHeader from '@/components/ui/UiPageHeader.vue';

import { BaseVue } from '../../BaseVue';
import ImportTransactions from '../../components/tools/Import.vue';

@Component({
  components: {
    ImportTransactions,
    UiPageHeader,
  },
})
export default class ImportClass extends BaseVue {
  public selectedTab = 0;
}
